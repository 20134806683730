import { NgxLoggerLevel } from 'ngx-logger';
/**
 * environement for bugfix
 */
export const environment = {
  production: false,
  baseUrl: 'https://www.dox2udev.com:98',
  baseUrlWS: 'https://www.get2dox.com:118',
  baseURL97: 'https://www.dox2udev.com:97',
  baseUrlWSIcon: 'https://www.get2dox.com:131',
  prepaid:"https://payments.dox2udev.com:8443",
  baseOrigin: "https://dox2udev.com",
  baseapp: "https://bugfix.dox2udev.com",
  httpUrl : "https://",
  domain : "dox2udev.com",
  logLevel: NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.DEBUG,
  enableSourceMaps: true,
  elasticdbaseurl : 'https://www.dox2udev.com:8800',
  paymentBaseUrl: 'https://www.dox2udev.com:8600',
  hostName: 'www.dox2udev.com',
  port: '98',
  username: 'bac68c55fd67ef746bbf',
  password: '8334a0506e22299fbaf9',
  apiKey: 'Bearer e2b2a8562e0258cad74572d1522d1919',
  DownloadTallyMessageID: '86',
  PartnerFormsMessageID: '113',
  ContactUsFormMessageID: '84',
  ConsultationFormMessageID: '83',
  BlogNewsletterMessageID: '82',
  EbookDownloadMessageID: '81',
  CreateWSMessageID: '80',
  spid: 10186
};