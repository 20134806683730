import { Component, OnInit, ElementRef, Renderer2, HostListener, PLATFORM_ID, Inject  } from '@angular/core';
import { MenuI } from 'src/app/interfaces/interfaces';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/email.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {

  isMenuOpen : boolean = false;
  showDropdown: boolean = true;
  displayNavbar: boolean= true;
  learnMoreNavigationonPricingPage: boolean = false;
  learnMoreNavigationonTallyPage: boolean = false;
  learnMoreNavigationonHomePage: boolean = false;

  menus: MenuI[] = [];
  clickedIndex: number | null = null;
  constructor(private router: Router, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: any,  private renderer: Renderer2, private emailService: EmailService) {
    this.menus = [
      { 
        name: 'Product', 
        path: '/',
        icon: '../../../assets/Image/Vector.svg',
        expand: 'dropdown',
        disabled: true,
      },
      { 
        name: 'dox2U for Tally', 
        path: '/tally' ,
        disabled: false,
      },
      { 
        name: 'Pricing', 
        path: '/pricing' ,
        disabled: false,
      },
      { 
        name: 'Partners', 
        path: '/partners' ,
        disabled: false,
      },
      { 
        name: 'Resources', 
        path: '/' ,
        icon: '../../../assets/Image/Vector.svg',
        expand: 'dropdown',
        disabled: true,
  
      },
      { 
        name: 'Contact Us', 
        path: '/contact-us' ,
        disabled: false,
      },
    ];
  }
  
  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      let windowURL = window.location.href.split('/')[3];
      console.log("windowURL", windowURL);
     // const navLinks = this.el.nativeElement.querySelectorAll('.nav-link');  
      
      if(windowURL.endsWith('tally')) {
        this.learnMoreNavigationonTallyPage = true;
      }
  
      else if(windowURL.includes('pricing')) {
        this.learnMoreNavigationonPricingPage = true;
      }
  
      else if((windowURL == '') || (windowURL == '#')) {
        this.learnMoreNavigationonHomePage = true;
      }
      
      this.emailService.headerOff.subscribe((res) => {
        if (res) {
          this.learnMoreNavigationonTallyPage = false;
          this.learnMoreNavigationonPricingPage = false;
          this.learnMoreNavigationonHomePage = false;
        }
      })
    }

  
  }

  
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    window.location.reload();
  }
  
  reloadPage() {
    //window.location.reload();
    let overlayOpacty = document.getElementById("overlay") as HTMLElement;
    overlayOpacty.classList.remove("active");
    let navbarSupportedContent = document.getElementById("navbarSupportedContent") as HTMLElement;
    navbarSupportedContent.classList.remove("show");
  }


  /**
   * This method will navigate to repsective page on clciking menu item and reload the page.
   * @param path 
   * @param i 
   */
  navigateTo(path: string, i: number) {
    if (i === 1 ) {
      //this.router.navigate([path]);
      console.log(window.location.toString())
      let getData = window.location.toString();
      let splitUrl = getData.split('/');
      console.log("path", splitUrl[0] + "//" + splitUrl[2] + '/tally')
      window.location.href = splitUrl[0] + "//" + splitUrl[2] + '/tally';

    }

    if (i === 2 ) {
      //this.router.navigate([path]);
      console.log(window.location.toString())
      let getData = window.location.toString();
      let splitUrl = getData.split('/');
      window.location.href = splitUrl[0] + "//" + splitUrl[2] + '/pricing';

    }

    if ( i === 3 ) {
      //this.router.navigate([path]);
      console.log(window.location.toString())
      let getData = window.location.toString();
      let splitUrl = getData.split('/');
      window.location.href = splitUrl[0] + "//" + splitUrl[2] + '/partners';

    }

    if ( i === 5 ) {
      //this.router.navigate([path]);
      console.log(window.location.toString())
      let getData = window.location.toString();
      let splitUrl = getData.split('/');
      window.location.href = splitUrl[0] + "//" + splitUrl[2] + '/contact-us';

    }
     
  }

  /**
   * Thie method will show the webpage in background of navbar in black color in resposnive 
   */
  showNavbar() {
    if (isPlatformBrowser(this.platformId)) {
      this.displayNavbar = true;
      setTimeout(() => {
        let overlayElem = document.getElementById('overlay') as HTMLElement;
        overlayElem.classList.add('active');
      }, 100);
    }
   

  }


  /**
   * This method will hide the dropdown
   */
  hideDropdown() {
    this.showDropdown = false;
    window.location.reload();
  }

  hideNavbar() {
    if (isPlatformBrowser(this.platformId)) {
      this.displayNavbar = false;
      setTimeout(() => {
        let overlayElem = document.getElementById('overlay') as HTMLElement;
        overlayElem.classList.remove('active');
      }, 100);
    }


  }

  

  toggleSubMenu(menu: MenuI) {
    if (menu.expand) {
      menu.expand = menu.expand === 'dropdown' ? 'expanded' : 'dropdown';
    }
  }


  scrollToGettingStarted() {
      let overlayOpacty = document.getElementById("overlay") as HTMLElement;
    overlayOpacty.classList.remove("active");
    let navbarSupportedContent = document.getElementById("navbarSupportedContent") as HTMLElement;
    navbarSupportedContent.classList.remove("show");
      const gettingStartedElement = document.getElementById('gettingStarted');
      if (gettingStartedElement) {
          const viewportHeight = window.innerHeight;
          const elementHeight = gettingStartedElement.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
          top: gettingStartedElement.offsetTop - yOffset + 200,
          behavior: 'smooth',
        };
          window.scrollTo(scrollOptions);
        //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
      }

    else {
      this.router.navigate(['/']).then(() => {
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            const elementAfterNavigation = document.getElementById('gettingStarted');
            if (elementAfterNavigation) {
              const viewportHeight = window.innerHeight;
              const elementHeight = elementAfterNavigation.clientHeight;
              const yOffset = (viewportHeight - elementHeight) / 2;
            const scrollOptions: ScrollToOptions = {
              top: elementAfterNavigation.offsetTop - yOffset + 200,
              behavior: 'smooth',
            };
            window.scrollTo(scrollOptions);
              //elementAfterNavigation.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        }

      });
    }
  }

  scrollTodCoinsSection() {
    const dcoinsElement = document.getElementById('dCoinsSection');
      if (dcoinsElement) {
          const viewportHeight = window.innerHeight;
          const elementHeight = dcoinsElement.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
          top: dcoinsElement.offsetTop - yOffset + 200,
          behavior: 'smooth',
        };
          window.scrollTo(scrollOptions);
        //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
      }
  }

  scrollToNewFeature() {
    this.router.navigate(['/tallybackup']);
    // const newFeature = document.getElementById('newFeature');
    // if (newFeature) {
    //     const viewportHeight = window.innerHeight;
    //     const elementHeight = newFeature.clientHeight;
    //     const yOffset = (viewportHeight - elementHeight) / 2;
    //     const scrollOptions: ScrollToOptions = {
    //     top: newFeature.offsetTop - yOffset,
    //     behavior: 'smooth',
    //   };
    //     window.scrollTo(scrollOptions);
    //   //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    // }
  }

  scrollToTallyBackup() {
    window.open('/tallybackup', '_blank');
  }

  scrollToExcitingUpdates() {
    this.router.navigate(['/tallybackup']);
    // const excitingUpdates = document.getElementById('tally-document');
    // if (excitingUpdates) {
    //     const viewportHeight = window.innerHeight;
    //     const elementHeight = excitingUpdates.clientHeight;
    //     const yOffset = (viewportHeight - elementHeight) / 2;
    //     const scrollOptions: ScrollToOptions = {
    //     top: excitingUpdates.offsetTop - yOffset,
    //     behavior: 'smooth',
    //   };
    //     window.scrollTo(scrollOptions);
    //   //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    // }
  }
}

  
